<template>
  <div class="base-container">
    <vxe-toolbar perfect size="mini">
      <template v-slot:buttons>
        <div class="cf table-header-padding">
          <div class="fl">
            <el-select v-model="search.type" clearable filterable placeholder="选择化验类型" size="mini"
                       style="width: 120px; margin-right: 10px">
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="search.typeId" clearable filterable placeholder="选择产品类型" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0">
              <el-option v-for="(item, index) in productType" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.productId" clearable filterable placeholder="选择产品" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0">
              <el-option v-for="(item, index) in product" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-date-picker @change="dateValue(search.value)" v-model="search.value" type="datetimerange" size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                            :default-time="['00:00:00', '23:59:59']" placeholder="选择日期时间" class="el-daterange-picker-radius"
                            style="width: 280px">
            </el-date-picker>
          </div>
          <div class="fr">
            <el-tooltip class="item" effect="dark" content="查询" placement="top">
              <el-button size="mini" @click="init()" circle class="el-icon-search">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="清空" placement="top">
              <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
              <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
              <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}" v-if="isShow"
               highlight-hover-row auto-resize size="small" ref="assayRecord" resizable row-id="id" v-loading="loading"
               element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow>
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column field="code" min-width="160" title="化验编号"></vxe-table-column>
      <vxe-table-column field="type" min-width="80" title="化验类型"></vxe-table-column>
      <vxe-table-column field="supplierName" min-width="160" title="供应商"></vxe-table-column>
      <vxe-table-column field="productName" min-width="150" title="产品"></vxe-table-column>
      <vxe-table-column field="carNum" min-width="80" title="化验车数"></vxe-table-column>
      <template v-for="(item, index) in assayList">
        <vxe-table-column :field="item.name" :title="item.label" :key="index" min-width="120"></vxe-table-column>
      </template>
    </vxe-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'assayRecord',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 147 + 'px',
      allAlign: 'center',
      loading: false,
      checkBox: [],
      checkLength: 0,
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      search: {},
      isNull: ['', null, undefined],
      isShow: false,
      assayList: [],
      typeList: [
        {value: 'Purchase', label: '采购'},
        {value: 'Sell', label: '销售'},
        {value: 'Sample', label: '样品'},
      ]
    }
  },
  created() {
    this.isShow = false
  },
  computed: { ...mapGetters(['product', 'productType'])},
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.assay) {
        this.tableHeight = window.innerHeight - this.$refs.assay.$el.offsetTop - 21 + 'px'
      }
    }
    this.$store.dispatch('getProductType')
    this.$store.dispatch('getProduct')
  },
  methods: {
    init() {
      this.loading = true
      this.isShow = false
      const params = {
        params: {
          startTime: this.search.startTime,
          endTime: this.page.endTime,
        },
      }
      if (!this.isNull.includes(this.search.productId)) params.params.productId = this.search.productId
      if (!this.isNull.includes(this.search.typeId)) params.params.typeId = this.search.typeId
      if (!this.isNull.includes(this.search.type)) params.params.type = this.search.type
      let isSearch = false
      const isTrue = this.isNull.includes(this.search.productId) && this.isNull.includes(this.search.typeId)
      const isFalse = !this.isNull.includes(this.search.productId) && !this.isNull.includes(this.search.typeId)
      if (this.$utils.getSize(params.params) >= 3) {
        if (this.isNull.includes(this.search.value)) {
          this.$message.warning('请选择时间区间')
          isSearch = false
        } else if (isTrue || isFalse) {
          this.$message.warning('请选择产品类型或产品其中一个条件')
          isSearch = false
        } else {
          isSearch = true
        }
      } else {
        this.$message.warning('请选择时间区间及产品类型或产品的一个条件')
      }
      if (isSearch) {
        let url = ``
        if (!this.isNull.includes(this.search.productId)) {
          url = `/order/assay/item/product/${this.search.productId}`
        } else if (!this.isNull.includes(this.search.typeId)) {
          url = `/order/assay/item/type/${this.search.typeId}`
        }
        this.$axios.get(url).then(response => {
          if (response) {
            this.assayList = response.data.data
            this.$axios.get('/order/assay/page', params).then((res) => {
              if (res) {
                const data = res.data.data
                this.data = data.list
                this.data.forEach(item => {
                  for (const key in item.params) {
                    item[key] = item.params[key]
                  }
                })
              }
              this.loading = false
              this.isShow = true
            })
                .catch(() => {
                  this.loading = false
                })
          }
        })
      }
    },
    exportDataEvent() {
      this.$refs.assayRecord.openExport()
    },
    printDataEvent() {
      this.$refs.assayRecord.openPrint()
    },
    searchInit() {
      this.isShow = false
    },
    dateValue(value) {
      if (value) {
        this.$set(this.search, 'startTime', value[0])
        this.$set(this.search, 'endTime', value[1])
      }
    },
  },
}
</script>

<style scoped></style>
